import { AxiosInstance } from "axios";
import { Language, Schedule, mapReplacer } from "..";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import {
  ProofOfActivityRequest,
  SchambeckUser,
  SimpleUser,
} from "./User.types";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";

/**
 * API Method to load a user by id
 * @param id id of the user to load
 * @param axios instance of axios
 * @returns loaded User instance or null in case of an error
 */
export const loadSchambeckUserById = async (
  id: string,
  axios: AxiosInstance
): Promise<SchambeckUser | null> => {
  return axios
    .get("/user/", { params: { id } })
    .then((res) => {
      if (res.data) return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadUserById"),
      });
      console.error("Error while loading user", exc);
      return null;
    });
};
/**
 * API Method to load a simple user by id
 * @param id id of the user to load
 * @param axios instance of axios
 * @returns loaded User instance or null in case of an error
 */
export const loadSimpleUserById = async (
  id: string,
  axios: AxiosInstance
): Promise<SimpleUser | undefined> => {
  return axios
    .get("/user/simple", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadUserById"),
      });
      console.error("Error while loading user", exc);
      return undefined;
    });
};

/**
 * API Method to load all simpleUsers with name and id
 * @param axios instance of axios
 * @returns array of found users or empty array
 */
export const loadAllSimpleUsers = async (
  axios: AxiosInstance
): Promise<SimpleUser[]> => {
  return axios
    .get("/user/simple/all/office")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllSimpleUsers"),
      });
      console.error("Error while loading simple users", exc);
      return [];
    });
};
/**
 * API Method to load all  simpleUsers with name and id !This API doesnt filter by office!
 * @param axios instance of axios
 * @returns array of found users or empty array
 */
export const loadAllSimpleUsersAllOffices = async (
  axios: AxiosInstance
): Promise<SimpleUser[]> => {
  return axios
    .get("/user/simple/all/")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllSimpleUsers"),
      });
      console.error("Error while loading simple users", exc);
      return [];
    });
};

/**
 * API method to load all users for the selected offices
 * @param axios instance of axios
 * @returns array with users or empty array if nothing is found
 */
export const loadAllSchambeckUsersForOffices = async (
  axios: AxiosInstance
): Promise<SchambeckUser[]> => {
  return axios
    .get("/user/all/office")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllUsers"),
      });
      console.error("Error while loading user", exc);
      return [];
    });
};
/**
 * API method to load all users
 * @param axios instance of axios
 * @returns array with users or empty array if nothing is found
 */
export const loadAllSchambeckUsers = async (
  axios: AxiosInstance
): Promise<SchambeckUser[]> => {
  return axios
    .get("/user/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllUsers"),
      });
      console.error("Error while loading user", exc);
      return [];
    });
};

/**
 * API method to load all active users
 * @param axios instance of axios
 * @returns array with users or empty array if nothing is found
 */
export const loadAllActiveSchambeckUsers = async (
  axios: AxiosInstance
): Promise<SchambeckUser[]> => {
  return axios
    .get("/user/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllUsers"),
      });
      console.error("Error while loading user", exc);
      return [];
    });
};
/**
 * API method to create a user
 * @param user to create
 * @param axios instance of axios
 * @returns createdUser or null
 */
export const createSchambeckUser = async (
  user: SchambeckUser,
  axios: AxiosInstance
): Promise<SchambeckUser | null> => {
  return axios
    .post("/user/", JSON.stringify(user, mapReplacer))
    .then((res) => {
      if (res.data) return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value:
          exc.response.status === 412
            ? i18n.t("general.notification.error.createUserPassword")
            : i18n.t("general.notification.error.createUser"),
      });
      console.error("Error while creating user", exc);
      return null;
    });
};
/**
 * API method to update a user
 * @param user to update
 * @param axios instance of axios
 * @returns updated user or null
 */
export const updateSchambeckUserTableConfig = async (
  axios: AxiosInstance,
  user: SchambeckUser,
  tableKey: TableKey,
  updatedConfig: TableHeader[]
): Promise<SchambeckUser | null> => {
  const updateValues: Map<TableKey, TableHeader[]> = new Map<
    TableKey,
    TableHeader[]
  >(user.tableConfig);
  updateValues.set(tableKey, updatedConfig);
  return axios
    .post(
      "/user/update",
      JSON.stringify({ ...user, tableConfig: updateValues }, mapReplacer)
    )
    .then((res) => {
      if (res.data) return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateUser"),
      });
      console.error("Error while creating user", exc);
      return null;
    });
};
/**
 * API method to update a user
 * @param user to update
 * @param axios instance of axios
 * @returns updated user or null
 */
export const updateSchambeckUser = async (
  user: SchambeckUser,
  axios: AxiosInstance
): Promise<SchambeckUser | null> => {
  return axios
    .post("/user/update", JSON.stringify(user, mapReplacer))
    .then((res) => {
      if (res.data) return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateUser"),
      });
      console.error("Error while creating user", exc);
      return null;
    });
};

/**
 * API method to delete user
 * @param id of the user to delete
 * @param axios  Instance of axios
 * @returns boolean if successful or not
 */
export const deleteSchambeckUser = async (
  id: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/user/delete", undefined, { params: { id } })
    .then((res) => res.status === 200)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.deleteUser"),
      });
      console.error("Error while deleting user", exc);
      return false;
    });
};

/**
 * API method to load all simpleUsers for a given role
 * @param axios instance of axios
 * @param roleId to load the users for
 * @returns Array of SimpleUsers
 */
export const loadSimpleUsersWithRole = async (
  axios: AxiosInstance,
  roleId: string
): Promise<SimpleUser[]> => {
  return axios
    .get("/user/simple/role", { params: { roleId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadSimpleUser"),
      });
      console.error("Error while loading simpleUsers for role", exc);
      return [];
    });
};

/**
 * API Method to get a pdf document containing the activity proofs
 * @param axios instance of Axios
 * @param request containing the userIds, language and period
 * @returns  Promise containing the document or undefined on error
 */
export const getProofOfActivityPdf = async (
  axios: AxiosInstance,
  request: ProofOfActivityRequest
): Promise<Blob | undefined> => {
  return axios
    .post("/export/pdf/proof", request, { responseType: "blob" })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      console.error("Error while creating proof of activity", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createActivityProof"),
      });
      return undefined;
    });
};

/**
 * API method to load ALL schedule entries for the given user id
 *
 * @param userId The id of the user to load the schedules for
 * @param axios The axios instance
 * @returns List of all schedules for the given user
 */
export const getAllSchedulesForUser = async (
  userId: string,
  axios: AxiosInstance
): Promise<Schedule[]> => {
  if (!userId) return [];
  return axios
    .get("/schedule/user/all", {
      params: { userId },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during schedule load for user", exc);
      generateNotification({
        value: i18n.t("general.notification.error.scheduleLoad"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API method to save a new schedule entry on the backend
 *
 * @param schedule The new schedule
 * @param axios The axios instance
 * @returns The newly created instance or undefined in case of an error
 */
export const generateNewSchedule = async (
  schedule: Schedule,
  axios: AxiosInstance
): Promise<Schedule | undefined> => {
  return axios
    .post("/schedule/", schedule)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during schedule creation", exc);
      generateNotification({
        value: i18n.t("general.notification.error.scheduleCreate"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API Method to update a schedule
 * @param axios instance of axios
 * @param schedule to update
 * @returns Promise containing the saved object or undefined on error
 */
export const updateSchedule = async (
  axios: AxiosInstance,
  schedule: Schedule
): Promise<Schedule | undefined> => {
  return axios
    .post("/schedule/update", schedule)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating schedule", exc);
      return undefined;
    });
};

/**
 * API Method to delete a schedule
 * @param axios instance of axios
 * @param scheduleId id of the schedule to delete
 * @returns Promise containing a boolean depending on the success
 */
export const deleteSchedule = async (
  axios: AxiosInstance,
  scheduleId: string
): Promise<boolean> => {
  return axios
    .post("/schedule/delete", scheduleId, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error while deleting schedule", exc);
      return false;
    });
};

/**
 * API Method to get a csv file containing an overview about all users
 * @param axios instance of axios
 * @param type decides which csv is created
 * @param language to create the document in
 * @returns Promise containing the svg file or undefined on error
 */
export const getUserOverviewCSV = async (
  axios: AxiosInstance,
  type: "short" | "full",
  language: Language
): Promise<Blob | undefined> => {
  return axios
    .get(`/export/csv/user${type === "short" ? "/short" : ""}`, {
      params: { language },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "text/csv;charset=utf-8" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getOverviewCsv"),
      });
      console.error("Error while loading csv for users", exc);
      return undefined;
    });
};
