import { Button, Dropdown, Input, Popup, Wysiwyg } from "@sam/components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language, MailConfiguration, getPopulatedMailTemplate } from "shared";
import { useUser } from "../UserContext";

interface MailConfigProps {
  isOpen: boolean;
  toggleOpen(open: boolean): void;
  updateConfig: (mailConfig: MailConfiguration) => void;
  mailConfig: MailConfiguration;
  children?: React.ReactNode;
  sendMail(): void;
  referenceId: string;
  userId: string;
  sendDates?: Date[];
}

/**
 * Holds the basic mail information
 */
export const MailPopup: React.FC<MailConfigProps> = ({
  mailConfig,
  isOpen,
  toggleOpen,
  updateConfig,
  sendMail,
  children,
  referenceId,
  userId,
  sendDates,
}) => {
  const { t } = useTranslation();
  const { axios } = useUser();
  const [showWarning, toggleShowWarning] = useState<boolean>();

  // load the correct template for the given type (does it only once during startup)
  useEffect(() => {
    if (referenceId && userId) {
      getPopulatedMailTemplate(
        axios,
        mailConfig.type,
        referenceId,
        userId,
        mailConfig.language || Language.DE
      ).then((template) => {
        updateConfig({
          ...mailConfig,
          content:
            template?.mailContent.get(mailConfig.language || Language.DE) ||
            "not found",
          subject:
            template?.subject.get(mailConfig.language || Language.DE) ||
            "not found",
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailConfig.type, referenceId, userId, isOpen]);

  /**
   * Hook to keep the warning page open or closed according to the sendDates
   */
  useEffect(
    () => toggleShowWarning(sendDates ? sendDates.length > 0 : false),
    [sendDates]
  );

  return (
    <>
      <Popup
        isOpen={isOpen}
        onClose={() => toggleOpen(false)}
        buttons={
          showWarning
            ? []
            : [
                <Button
                  value={t("general.buttons.sendAndSave")}
                  onClick={sendMail}
                />,
                <Button
                  value={t("general.buttons.cancel")}
                  onClick={() => toggleOpen(false)}
                />,
              ]
        }
        title={t("pages.invoice.edit.mailPopup")}
      >
        {showWarning ? (
          <div className="mail-popup__warning__wrapper">
            <p className="mail-popup__warning__text">
              {t("pages.invoice.edit.sendMailInfoText")}
            </p>
            <ul className="mail-popup__warning__dates">
              {sendDates?.map((date) => (
                <li
                  className="mail-popup__warning__dates__entry"
                  key={`previously-sent-${date.toISOString()}`}
                >
                  {`${date.toLocaleDateString(
                    "DE-de"
                  )}, ${date.toLocaleTimeString("DE-de")}`}
                </li>
              ))}
            </ul>
            <div className="mail-popup__warning__button-wrapper">
              <Button
                value={t("general.buttons.cancel")}
                onClick={() => toggleOpen(false)}
              />
              <Button
                value={t("general.buttons.confirm")}
                onClick={() => toggleShowWarning(false)}
              />
            </div>
          </div>
        ) : (
          <>
            <>
              <Input
                type="text"
                value={mailConfig.receiver}
                label={t("pages.invoice.edit.invoiceMailRecipient")}
                disabled
              />
              <Dropdown
                options={mailConfig.receiverCC.map((receiver) => ({
                  label: receiver,
                  value: receiver,
                }))}
                selectedMultiOptions={mailConfig.receiverCC}
                multi
                create
                onChangeMultiple={(receiverCC) => {
                  updateConfig({ ...mailConfig, receiverCC });
                }}
                label={t("pages.invoice.edit.invoiceMailRecipientCC")}
              />
              <Input
                label={t("pages.invoice.edit.subject")}
                type="text"
                value={mailConfig.subject}
                onChange={(subject) => updateConfig({ ...mailConfig, subject })}
              />
              <Wysiwyg
                initialContent={mailConfig.content}
                onChange={(content) => updateConfig({ ...mailConfig, content })}
              />
            </>
            <div className="mail-popup__additional__wrapper">{children}</div>
          </>
        )}
      </Popup>
    </>
  );
};
