import { Box, Checkbox, Dropdown, Input, TopBar } from "@sam/components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Company,
  DocType,
  DocumentBucket,
  FileUploadConfig,
  generateCountryCodeOptions,
  generateDropdownOptionsForLanguage,
  generateNotification,
  Language,
  saveNewFile,
} from "shared";
import {
  createNewCompany,
  updateCompany,
} from "shared/src/company/Company.axios";
import { NotificationType } from "shared/src/notification/notification.types";
import { FileOverview } from "../../components/files/FileOverview";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateEmptyCompany } from "../../utils/company/company.utils";

export const CompanyCreateOrEdit: React.FC = () => {
  const location = useLocation<{ company?: Company }>();
  const isEdit: boolean = !!location.state?.company;
  const [companyToEdit, setCompanyToEdit] = useState<Company>(
    location.state?.company ? location.state.company : generateEmptyCompany()
  );
  const [selectedAgbLanguage, setSelectedAgbLanguage] = useState<Language>();
  const { axios, updateFootnoteConfig, user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);
  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(companyToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyToEdit.createDate, updateFootnoteConfig]);

  /**
   *  Util method to update the AGBs of the company
   * @param file uploaded File
   */
  const handleFileUpload = (file: File): void => {
    const fileName: string = `${t(
      "pages.company.edit.agbFilename"
    )}-${selectedAgbLanguage}.pdf`;
    const uploadConfig: FileUploadConfig = {
      bucket: DocumentBucket.COMPANY,
      docType: DocType.AGB,
      fileName,
      path: `${companyToEdit.id}/agb/`,
      userId: user.id,
      orderId: "",
      customerId: "",
    };
    saveNewFile(axios, uploadConfig, file).then((newSchambeckDoc) => {
      if (!newSchambeckDoc) return;
      let oldAgbs: Map<Language, string> = companyToEdit.agbPath;
      if (!oldAgbs) oldAgbs = new Map<Language, string>();
      oldAgbs.set(
        selectedAgbLanguage || Language.DE,
        `${companyToEdit.id}/agb/${fileName}`
      );
      setCompanyToEdit({
        ...companyToEdit,
        agbPath: oldAgbs,
      });
    });
  };
  /**
   * Helper method to update or create a new company onSubmit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    isEdit
      ? updateCompany(axios, companyToEdit).then((updatedCompany) => {
          if (updatedCompany && redirect) navigate("/company");
          if (updatedCompany) {
            setCompanyToEdit(updatedCompany);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        })
      : createNewCompany(axios, companyToEdit).then((createdCompany) => {
          if (createdCompany && redirect) navigate("/company");
          if (createdCompany) {
            setCompanyToEdit(createdCompany);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        });
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          isEdit
            ? t("pages.company.edit.topBarHeadlineEdit")
            : t("pages.company.edit.topBarHeadlineCreate")
        }
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box title={t("pages.company.edit.editData")}>
        <div className="three-columns">
          <Input
            type="text"
            onChange={(name) => setCompanyToEdit({ ...companyToEdit, name })}
            value={companyToEdit.name}
            label={t("pages.company.edit.name")}
          />
          <Input
            value={companyToEdit.numberIdentifier}
            type="number"
            onChangeNumber={(numberIdentifier) =>
              setCompanyToEdit({ ...companyToEdit, numberIdentifier })
            }
            label={t("pages.company.edit.numberIdentifier")}
          />
          <Checkbox
            isChecked={!companyToEdit.disabled}
            onCheck={(checked) =>
              setCompanyToEdit({ ...companyToEdit, disabled: !checked })
            }
            label={t("pages.company.edit.active")}
          />
          <Input
            value={companyToEdit.taxRate}
            type="number"
            onChangeNumber={(taxRate) =>
              setCompanyToEdit({ ...companyToEdit, taxRate })
            }
            label={t("pages.company.edit.taxRate")}
          />
          <Input
            value={companyToEdit.wkz}
            type="text"
            onChange={(wkz) => setCompanyToEdit({ ...companyToEdit, wkz })}
            label={t("pages.company.edit.wkz")}
          />
          <Dropdown
            options={Object.values(Language).map((language) => ({
              label: t(`general.languages.${language}`),
              value: language,
            }))}
            selectedOption={companyToEdit.receiptLanguage}
            label={t("pages.company.edit.receiptLanguage")}
            onChange={(receiptLanguage: Language) =>
              setCompanyToEdit({ ...companyToEdit, receiptLanguage })
            }
          />
          <Input
            value={companyToEdit.ceoName}
            type="text"
            onChange={(ceoName) =>
              setCompanyToEdit({ ...companyToEdit, ceoName })
            }
            label={t("pages.company.edit.ceoName")}
          />
          <Input
            value={companyToEdit.location}
            type="text"
            onChange={(location) =>
              setCompanyToEdit({ ...companyToEdit, location })
            }
            label={t("pages.company.edit.location")}
          />
          <Input
            value={companyToEdit.courtLocation}
            type="text"
            onChange={(courtLocation) =>
              setCompanyToEdit({ ...companyToEdit, courtLocation })
            }
            label={t("pages.company.edit.courtLocation")}
          />
          <Dropdown
            label={t("pages.company.edit.country")}
            options={generateCountryCodeOptions()}
            selectedOption={companyToEdit.countryCode}
            onChange={(countryCode) =>
              setCompanyToEdit({ ...companyToEdit, countryCode })
            }
          />
          <Input
            value={companyToEdit.nightBonusStart}
            type="time"
            onChangeTime={(nightBonusStart) =>
              setCompanyToEdit({ ...companyToEdit, nightBonusStart })
            }
            label={t("pages.company.edit.nightBonusStart")}
          />
          <Input
            value={companyToEdit.nightBonusEnd}
            type="time"
            onChangeTime={(nightBonusEnd) =>
              setCompanyToEdit({ ...companyToEdit, nightBonusEnd })
            }
            label={t("pages.company.edit.nightBonusEnd")}
          />
          <Input
            value={companyToEdit.taxId}
            type="text"
            onChange={(taxId) => setCompanyToEdit({ ...companyToEdit, taxId })}
            label={t("pages.company.edit.taxId")}
          />
          <Input
            value={companyToEdit.vatId}
            type="text"
            onChange={(vatId) => setCompanyToEdit({ ...companyToEdit, vatId })}
            label={t("pages.company.edit.vatId")}
          />
        </div>
      </Box>
      <Box title={t("pages.company.edit.agbBoxTitle")}>
        <div className="company-edit__agb-wrapper">
          <Dropdown
            label={t("pages.company.edit.language")}
            options={generateDropdownOptionsForLanguage()}
            onChange={(language) =>
              setSelectedAgbLanguage(language as Language)
            }
          />
          <Input
            label={t("pages.company.edit.agbUpload")}
            type="file"
            onChangeFile={handleFileUpload}
            value={undefined}
            disabled={!selectedAgbLanguage}
          />
        </div>
        <FileOverview
          upload={false}
          bucket={DocumentBucket.COMPANY}
          path={`${companyToEdit.id}/agb/`}
        />
      </Box>
    </form>
  );
};
