import { Box, Button, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Order,
  Receipt,
  SAPExportDataModel,
  getAllOpenInvoices,
  getReceiptExportPreview,
  getReceiptSAPExportFiles,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { useUser } from "../../components/UserContext";
import { downloadTextFile } from "../../utils/files/Files.utils";
import { convertInvoicesIntoAccountingTableEntries } from "../../utils/invoice/Invoice.utils";

export const AccountingOverview: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user, updateContextUser } = useUser();
  const [invoices, setInvoices] = useState<Receipt[]>([]);
  const [previewModels, setPreviewModels] = useState<SAPExportDataModel[]>([]);
  const [selectedReceipts, setSelectedReceipts] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [dateUntil, setDateUntil] = useState<Date>(new Date());
  const [selectedCompany, setSelectedCompany] = useState<string>();

  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedProjects } = useData("PROJECT_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL", {
    config: { fallbackData: [] },
  });
  const { data: currencyConversions } = useData("CURRENCY_CONVERSIONS_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: currencies } = useData("CURRENCY_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCompanies } = useData("COMPANY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  /**
   * useEffect to load all invoices in the state PENDING
   */
  useEffect(() => {
    getAllOpenInvoices(axios, dateUntil).then((loadedInvoices) => {
      const ids: string[] = loadedInvoices.map((invoice) => invoice.id);
      getReceiptExportPreview(axios, ids).then(setPreviewModels);
      setInvoices(loadedInvoices);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateUntil]);

  /**
   * Holds the invoices that are connected to the selected Company
   * Holds an empty array, if no company is selected
   */
  const companyInvoices: Receipt[] = useMemo((): Receipt[] => {
    if (!selectedCompany) return [];
    const companyInvoices: Receipt[] = invoices.filter((invoice) => {
      const order: Order | undefined = loadedOrders.find(
        (order) => order.id === invoice.orderId
      );
      return order?.companyId === selectedCompany;
    });

    return companyInvoices;
  }, [invoices, loadedOrders, selectedCompany]);

  /**
   * Contains the tableRows with the converted invoices
   */
  const invoiceRows: TableRow[] = useMemo((): TableRow[] => {
    if (!selectedCompany) return [];
    return convertInvoicesIntoAccountingTableEntries(
      companyInvoices,
      loadedOrders,
      loadedCustomers,
      loadedProjects,
      loadedLocations,
      previewModels,
      setSelectedReceipts,
      selectedReceipts,
      loadedOffices,
      loadedBusinessAreas,
      currencies,
      currencyConversions
    );
  }, [
    selectedCompany,
    companyInvoices,
    loadedOrders,
    loadedCustomers,
    loadedProjects,
    loadedLocations,
    previewModels,
    selectedReceipts,
    loadedOffices,
    loadedBusinessAreas,
    currencies,
    currencyConversions,
  ]);

  return (
    <>
      <TopBar
        title={t("pages.accounting.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        <Button
          backgroundColor="#bc2e46"
          value={t("general.buttons.export")}
          onClick={() =>
            getReceiptSAPExportFiles(axios, selectedReceipts).then((file) => {
              if (!file) return;
              downloadTextFile(file.headerFile, "header.txt");
              downloadTextFile(file.detailFile, "detail.txt");
            })
          }
        />
      </TopBar>
      <Box>
        <Input
          label={t("pages.accounting.overview.dateUntil")}
          maxWidth={200}
          type="date"
          value={dateUntil}
          onChangeDate={(date) => date && setDateUntil(date)}
        />
        <div className="three-columns">
          {loadedCompanies.map((company) => (
            <Button
              value={company.name}
              onClick={() => setSelectedCompany(company.id)}
              active={company.id === selectedCompany}
            />
          ))}
        </div>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.ACCOUNTING_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.ACCOUNTING_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={invoiceRows}
          header={
            t("pages.accounting.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
