import { Checkbox, Input, TableRow } from "@sam/components";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  addOrRemove,
  BusinessArea,
  Company,
  Country,
  Currency,
  CurrencyConversion,
  Customer,
  getCurrencyStringValue,
  InvoicePayment,
  InvoicePosition,
  Offer,
  Office,
  Order,
  PaymentCondition,
  ProjectProtocol,
  Receipt,
  SAPExportDataModel,
  SimpleUser,
} from "shared";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { Project } from "shared/src/project/Project.types";
import dayjs from "shared/src/tools/Dayjs";
import { uid } from "uid";
import {
  ReactComponent as DeleteIcon,
  ReactComponent as DisableIcon,
} from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as EuroIcon } from "../../assets/euro.svg";
import i18n from "../../i18n/i18n";
import { getUserNameForSimpleUser } from "../user/User.utils";

/**
 * Util method to convert paymentConditions into TableRows
 * @param conditions to convert
 * @param navigate to navigate to edit
 * @returns  Array of TableRows
 */
export const convertPaymentConditionsIntoTableEntries = (
  conditions: PaymentCondition[],
  navigate: NavigateFunction,
  handleDisable: (id: string) => void
): TableRow[] => {
  return conditions.map((condition) => ({
    id: condition.id,
    onClick: () =>
      navigate("/payment/edit", {
        state: { paymentCondition: condition },
      }),
    content: [
      condition.identifier,
      condition.description,
      i18n.t(`general.country.${condition.country}`),
      <div className="table-action__icon-wrapper">
        <EditIcon
          title={i18n.t("general.icons.edit")}
          className="edit"
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/payment/edit", {
              state: { paymentCondition: condition },
            });
          }}
          width={30}
        />
        <DisableIcon
          className="delete"
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            handleDisable(condition.id);
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to generate an empty paymentCondition
 * @param override partial PaymentCondition to adjust the gernerated condition
 * @returns created PaymentCondition
 */
export const generateEmptyPaymentCondition = (
  override?: Partial<PaymentCondition>
): PaymentCondition => ({
  country: Country.GERMANY,
  createDate: new Date(),
  createdBy: "",
  daysToPay: 0,
  daysToPayDiscount: 0,
  deactivated: false,
  description: "",
  discountPercentage: 1,
  id: undefined!,
  identifier: "",
  lastUpdated: new Date(),
  updatedBy: "",
  ...override,
});

/**
 * Util method to convertInvoices into TableRows
 * @param invoices to convert
 * @returns Array of TableRows
 */
export const convertInvoicesIntoTableRows = (
  invoices: Receipt[],
  orders: Order[],
  projects: Project[],
  customers: Customer[],
  locations: CustomerLocation[],
  navigate: NavigateFunction,
  currencyConversions: CurrencyConversion[],
  currencies: Currency[],
  companies: Company[]
): TableRow[] => {
  return invoices.map((invoice) => {
    const invoiceSum: number = getTotalInvoiceSum(invoice);

    const order: Order | undefined = orders?.find(
      (order) => order.id === invoice.orderId
    );
    const project: Project | undefined = projects.find(
      (project) => project.orderId === order?.id
    );

    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";

    const customer: string =
      customers.find((customer) => customer.id === order?.customerId)?.name ||
      "-";

    const workLocation: string =
      locations.find(
        (customer) => customer.id === order?.workingLocation.customerLocationId
      )?.name || "-";

    const factor: number =
      currencyConversions.find(
        (conversion) => conversion.currencyId === order?.currencyId
      )?.factor || 1;
    const currencySymbol: string =
      currencies.find((currency) => currency.id === order?.currencyId)
        ?.symbol || "";

    const company: Company | undefined = companies.find(
      (company) => company.id === order?.companyId
    );
    const taxRate: number =
      company &&
      company.countryCode ===
        customers.find(
          (customer) => customer.id === order?.invoiceRecipient.customerId
        )?.contact.countryCode
        ? company.taxRate / 100
        : 0;
    return {
      id: invoice.id,
      content: [
        project?.numberRangeNumber || "-",
        invoice.invoiceNumber,
        invoiceRecipient,
        customer,
        workLocation,
        invoice.deliveryDate.toLocaleDateString("DE-de"),
        i18n.t(`general.invoiceState.${invoice.invoiceState}`),
        invoice.exportedAt?.toLocaleDateString("DE-de") || "-",
        `${getCurrencyStringValue(invoiceSum)} ${currencySymbol}`,
        `${getCurrencyStringValue(invoiceSum * taxRate)} ${currencySymbol}`,
        `${getCurrencyStringValue(
          invoiceSum + invoiceSum * taxRate
        )} ${currencySymbol}`,
        `${getCurrencyStringValue(invoiceSum / factor)} €`,
        <div className="table-action__icon-wrapper">
          <EuroIcon
            title={i18n.t("general.icons.payment")}
            onClick={(evt) => {
              evt.stopPropagation();
              navigate("/accounting/invoice", { state: { invoice, order } });
            }}
          />
        </div>,
      ],
      onClick: () => navigate("/invoice/edit", { state: { invoice } }),
    };
  });
};

/**
 * Util method to convert InvoicePositions into TableEntries
 * @param positions to convert
 * @param updatePosition util method to update the positions onChange
 * @param readOnly decides if the inputs are disabled or not
 * @returns Array of TableRows
 */
export const convertInvoicePositionIntoTableEntries = (
  positions: InvoicePosition[],
  updatePosition: (
    id: string,
    key: keyof InvoicePosition,
    value: string | number | Date
  ) => void,
  handleDeletePosition: (id: string) => void,
  readOnly: boolean
): TableRow[] => {
  return positions
    .sort((a, b) => a.index - b.index)
    .map((position) => ({
      id: position.id,
      content: [
        <Input
          type="number"
          value={position.index}
          onChangeNumber={(value) =>
            updatePosition(position.id, "index", value)
          }
        />,
        <Input
          type="text"
          value={position.articleNumber}
          disabled={readOnly}
          onChange={(articleNumber) =>
            updatePosition(position.id, "articleNumber", articleNumber)
          }
        />,
        <Input
          type="number"
          value={position.amount}
          onChangeNumber={(amount) =>
            updatePosition(position.id, "amount", amount)
          }
          onBlur={(amount) =>
            Number(amount) &&
            updatePosition(position.id, "amount", Number(amount))
          }
          disabled={readOnly}
        />,
        <Input
          type="text"
          value={position.description}
          onChange={(description) =>
            updatePosition(position.id, "description", description)
          }
          disabled={readOnly}
        />,
        <Input
          type="number"
          onChangeNumber={(discount) =>
            updatePosition(position.id, "discount", discount)
          }
          value={position.discount}
          disabled={readOnly}
        />,
        <Input
          value={position.price}
          type="number"
          onChangeNumber={(price) =>
            updatePosition(position.id, "price", price)
          }
          onBlur={(price) =>
            Number(price) &&
            updatePosition(
              position.id,
              "price",
              getCurrencyStringValue(Number(price))
            )
          }
          disabled={readOnly}
        />,
        <Input
          type="number"
          onChangeNumber={() => {}}
          value={(position.amount * position.price - position.discount).toFixed(
            2
          )}
          disabled
        />,
        <div className="invoice-edit__table-icon-wrapper">
          {!readOnly && (
            <DeleteIcon
              title={i18n.t("general.icons.delete")}
              className="invoice-edit__table-icon-wrapper__icon"
              onClick={(evt) => {
                evt.stopPropagation();
                handleDeletePosition(position.id);
              }}
            />
          )}
        </div>,
      ],
    }));
};

/**
 * Util method to convert projects into TableEntries to be displayed
 * @param projects Array of projects that should be displayed
 * @param orders to display additional Data
 * @param customers to display additional Data
 * @param customerLocations to display additional Data
 * @param protocols to display additional Data
 * @param offers to display additional Data
 * @param onCheckboxClick handler function to add or remove the projects onClick
 * @param selectedProjects  all checked projects
 * @returns  Array of TableRows
 */
export const convertProjectsIntoInvoiceTableEntries = (
  projects: Project[],
  orders: Order[],
  customers: Customer[],
  customerLocations: CustomerLocation[],
  protocols: ProjectProtocol[],
  offers: Offer[],
  onCheckboxClick: (orderId: string) => void,
  selectedProjects: string[]
): TableRow[] => {
  return projects.map((project) => {
    const order: Order | undefined = orders?.find(
      (order) => order.id === project.orderId
    );
    const customer: Customer | undefined = customers.find(
      (customer) => customer.id === order?.customerId
    );
    const customerLocation: CustomerLocation | undefined =
      customerLocations.find((location) => location.id === order?.locationId);

    const offer: Offer | undefined = offers.find(
      (offer) => offer.id === project.acceptedOfferId
    );

    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";
    return {
      id: project.id,
      content: [
        <Checkbox
          isChecked={selectedProjects.includes(project.id)}
          onCheck={() => onCheckboxClick(project.id)}
        />,
        project.numberRangeNumber || "-",
        customer?.name || "-",
        customerLocation?.name || "-",
        invoiceRecipient,
        offer?.annotation || "-",
        order?.id ? getLastProtocolDate(protocols, order.id) : "-",
        order?.lastInvoiced?.toLocaleDateString("de-DE") || "-",
      ],
    };
  });
};

/**
 * Util method to get the newest protocolDate for an order out of an array of protocols
 * @param protocols to check
 * @param orderId to get the last protocolDate for
 * @returns last protocolDate or "-" if no protocol for the order was found
 */
export const getLastProtocolDate = (
  protocols: ProjectProtocol[],
  orderId: string
): string =>
  protocols
    .filter((protocol) => protocol.orderId === orderId)
    .sort((a, b) =>
      dayjs(a.protocolDate).isBefore(b.protocolDate) ? 1 : -1
    )[0]
    ?.protocolDate.toLocaleDateString("de-DE") || "-";

/**
 * Util method to generate an empty invoicePosition
 * @param override partial invoicePosition to adjust the document
 * @returns  created invoicePosition
 */
export const generateEmptyInvoicePosition = (
  override?: Partial<InvoicePosition>
): InvoicePosition => ({
  amount: 0,
  articleNumber: "",
  description: "",
  discount: 0,
  id: uid(),
  index: 0,
  price: 0,
  unitCode: "",
  ...override,
});

/**
 * Util method to display invoices for accounting
 * @param invoices list of invoices to display
 * @param orders array of orders to get information about the invoice
 * @param customers array of customers to display the customerName
 * @param offices array of offices to get the executing office
 * @returns Array of TableRows
 */
export const convertInvoicesIntoAccountingTableEntries = (
  invoices: Receipt[],
  orders: Order[],
  customers: Customer[],
  projects: Project[],
  locations: CustomerLocation[],
  exportData: SAPExportDataModel[],
  selectEntries: Dispatch<SetStateAction<string[]>>,
  selectedEntries: string[],
  offices: Office[],
  areas: BusinessArea[],
  currencies: Currency[],
  currencyConversions: CurrencyConversion[]
): TableRow[] => {
  return invoices.map((invoice) => {
    const order: Order | undefined = orders.find(
      (order) => order.id === invoice.orderId
    );
    const office: Office | undefined = offices.find(
      (office) => office.id === order?.officeId
    );
    const customer: Customer | undefined = customers.find(
      (customer) => customer.id === order?.customerId
    );
    const project: Project | undefined = projects.find(
      (project) => project.id === invoice.projectId
    );
    const area: BusinessArea | undefined = areas.find(
      (area) => area.id === order?.businessAreaId
    );
    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";

    const workLocation: string =
      locations.find(
        (customer) => customer.id === order?.workingLocation.customerLocationId
      )?.name || "-";

    const invoiceSum: number = getTotalInvoiceSum(invoice);

    const exportModel: SAPExportDataModel | undefined = exportData.find(
      (entry) => entry.receiptId === invoice.id
    );
    const currencySymbol: string =
      currencies.find((currency) => currency.id === order?.currencyId)
        ?.symbol || "";
    const currencyFactor: number =
      currencyConversions.find(
        (conversion) => conversion.currencyId === order?.currencyId
      )?.factor || 1;
    return {
      id: invoice.id,
      content: [
        <Checkbox
          onCheck={() => selectEntries((prev) => addOrRemove(prev, invoice.id))}
          isChecked={selectedEntries.includes(invoice.id)}
        />,
        office?.name || "-",
        area?.name || "-",
        project?.numberRangeNumber || "-",
        customer?.name || "-",
        invoiceRecipient,
        workLocation,
        invoice.invoiceNumber,
        invoice.invoiceDate.toLocaleDateString("DE-de"),
        invoice.deliveryDate.toLocaleDateString("DE-de"),
        `${getCurrencyStringValue(
          invoiceSum / currencyFactor
        )} ${currencySymbol}`,
        exportModel?.ktoSoll || "-",
        exportModel?.ktoHaben || "-",
        exportModel?.kst || "-",
      ],
    };
  });
};

/**
 * Util method to generate an empty InvoicePayment
 * @param override partial InvoicePayment to adjust the created object
 * @returns  generated InvoicePayment object
 */
export const generateEmptyInvoicePayment = (
  override?: Partial<InvoicePayment>
): InvoicePayment => ({
  amount: 0,
  completePayment: false,
  createDate: undefined!,
  createdBy: "",
  customerId: "",
  id: undefined!,
  invoiceId: "",
  orderId: "",
  paymentDate: new Date(),
  ...override,
});

/**
 * Util method to get the total invoiceSum of an invoice
 * @param invoice to calculate the sum for
 * @returns  sum of the positions on the invoice
 * @tested
 */
export const getTotalInvoiceSum = (invoice: Receipt): number => {
  let sum: number = 0;
  invoice.invoicePositions.forEach(
    (position) => (sum += position.amount * position.price)
  );
  return sum;
};

/**
 * Util method to convert due invoices into tableEntries to create dunnings
 * @param receipts receipts to display
 * @param selectedReceipts decides if the checkboxes is checked
 * @param setSelectedReceipts update method to keep track of the selected receipts
 * @param orders to get the customer data
 * @param customers  to get the customer and invoice recipient
 * @returns Array of TableRows
 */
export const convertDueInvoicesIntoTableEntries = (
  receipts: Receipt[],
  selectedReceipts: string[],
  setSelectedReceipts: Dispatch<SetStateAction<string[]>>,
  orders: Order[],
  customers: Customer[],
  maxDunningLevel: number,
  companies: Company[],
  currencies: Currency[],
  payments: InvoicePayment[]
): TableRow[] =>
  receipts.map((receipt) => {
    const order: Order | undefined = orders.find(
      (orderEntry) => orderEntry.id === receipt.orderId
    );
    const invoiceRecipient: Customer | undefined = customers.find(
      (customerEntry) => customerEntry.id === order?.invoiceRecipient.customerId
    );
    const company: Company | undefined = companies.find(
      (company) => company.id === order?.companyId
    );
    const taxRate: number =
      company &&
      company.countryCode ===
        customers.find(
          (customer) => customer.id === order?.invoiceRecipient.customerId
        )?.contact.countryCode
        ? company.taxRate / 100
        : 0;
    const dueDate: dayjs.Dayjs = dayjs(receipt.invoiceDate);
    dueDate.add(receipt.paymentCondition.daysToPay, "days");
    const currency: Currency | undefined = currencies.find(
      (currency) => currency.id === order?.currencyId
    );
    const invoiceSum: number = getTotalInvoiceSum(receipt);
    const paymentsUntilNow: number = payments
      .map((payment) => (payment.invoiceId === receipt.id ? payment.amount : 0))
      .reduce((a, b) => a + b, 0);
    return {
      id: receipt.id,
      content: [
        <Checkbox
          onCheck={() =>
            setSelectedReceipts(addOrRemove(selectedReceipts, receipt.id))
          }
          disabled={receipt.dunningLevel >= maxDunningLevel}
          isChecked={selectedReceipts.includes(receipt.id)}
        />,
        order?.customerOrderNumber || "-",
        receipt.invoiceDate.toLocaleDateString("DE-de"),
        receipt.invoiceNumber,
        receipt.deliveryDate.toLocaleDateString("DE-de"),
        invoiceRecipient?.name || "-",
        `${getCurrencyStringValue(invoiceSum)} ${currency?.symbol}`,
        `${getCurrencyStringValue(
          taxRate !== 0
            ? invoiceSum - paymentsUntilNow / (taxRate + 1)
            : invoiceSum - paymentsUntilNow
        )} ${currency?.symbol}`,
        `${getCurrencyStringValue(invoiceSum + invoiceSum * taxRate)} ${
          currency?.symbol
        }`,
        `${getCurrencyStringValue(paymentsUntilNow)} ${currency?.symbol}`,

        `${getCurrencyStringValue(
          invoiceSum + invoiceSum * taxRate - paymentsUntilNow
        )} ${currency?.symbol}`,
        dueDate.toDate().toLocaleDateString("DE-de"),
        receipt.dunningLevel,
      ],
    };
  });

/**
 * API Method to dispaly existing payments for an invoice
 * @param payments to display
 * @returns TableRows for the payments
 */
export const convertExistingPaymentsIntoTableEntries = (
  payments: InvoicePayment[],
  users: SimpleUser[],
  setPaymentToEdit: Dispatch<SetStateAction<InvoicePayment>>,
  handleDeletePayment: (id: string) => void
): TableRow[] =>
  payments.map((payment) => ({
    id: payment.id,
    content: [
      payment.paymentDate.toLocaleDateString("DE-de"),
      getCurrencyStringValue(payment.amount),
      getUserNameForSimpleUser(payment.createdBy, users),
      <div className="table-action__icon-wrapper">
        <EditIcon
          className="edit"
          onClick={(evt) => {
            evt.stopPropagation();
            setPaymentToEdit(payment);
          }}
        />
        <DeleteIcon
          className="delete"
          onClick={(evt) => {
            evt.stopPropagation();
            handleDeletePayment(payment.id);
          }}
        />
      </div>,
    ],
  }));
